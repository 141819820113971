var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"1"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-text-field',{attrs:{"rules":[_vm.rules.phone],"prepend-inner-icon":"mdi-numeric-positive-1","outlined":"","dense":"","clearable":"","label":"Search by Number","disabled":_vm.filterpatient != null || _vm.filteremployee != null},on:{"click:clear":_vm.clearFilters},model:{value:(_vm.filternumber),callback:function ($$v) {_vm.filternumber=$$v},expression:"filternumber"}})],1),_c('v-col',[_c('v-autocomplete',{staticClass:"mx-1",attrs:{"disabled":_vm.filteremployee != null ||
            (_vm.filternumber != null && _vm.filternumber != ''),"label":'Search by ' + _vm.getpatientN + 's',"items":_vm.patients,"loading":_vm.loadingEmployees,"filter":function (item, queryText) {
              return item.fullname
                .toLowerCase()
                .includes(queryText.toLowerCase());
            },"item-text":"fullname","item-value":"homePhone","autocomplete":"off","clearable":"","hide-details":"","outlined":"","dense":""},on:{"click:clear":_vm.clearFilters},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.fullname)}}),(data.item.homePhone)?_c('v-list-item-subtitle',{staticClass:"text-capitalize",domProps:{"innerHTML":_vm._s(data.item.homePhone)}}):_vm._e()],1)]}}]),model:{value:(_vm.filterpatient),callback:function ($$v) {_vm.filterpatient=$$v},expression:"filterpatient"}})],1),(_vm.isSuper || _vm.isAdmin)?[_c('v-col',[_c('v-autocomplete',{staticClass:"ml-2",attrs:{"label":"Search by Employees","items":_vm.employees,"loading":_vm.loadingEmployees,"disabled":_vm.filterpatient != null ||
              (_vm.filternumber != null && _vm.filternumber != ''),"filter":function (item, queryText) {
                return item.fullname
                  .toLowerCase()
                  .includes(queryText.toLowerCase());
              },"item-text":"fullname","item-value":"phone","autocomplete":"off","clearable":"","hide-details":"","outlined":"","dense":""},on:{"click:clear":_vm.clearFilters},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.fullname)}}),(data.item.ocupation)?_c('v-list-item-subtitle',{staticClass:"text-capitalize",domProps:{"innerHTML":_vm._s(data.item.ocupation.name)}}):_vm._e(),(data.item.phone)?_c('v-list-item-subtitle',{staticClass:"text-capitalize",domProps:{"innerHTML":_vm._s(data.item.phone)}}):_vm._e()],1)]}}],null,false,1535484464),model:{value:(_vm.filteremployee),callback:function ($$v) {_vm.filteremployee=$$v},expression:"filteremployee"}})],1)]:_vm._e(),_c('v-col',{},[_c('ma-date-picker',{attrs:{"label":"From","past":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1),_c('v-col',{staticClass:"mx-2"},[_c('ma-date-picker',{attrs:{"label":"To","past":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1),(_vm.validClean)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","elevation":"0","small":"","rounded":"","depressed":"","fab":""},on:{"click":_vm._clearFilters}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-backup-restore")])],1)]}}],null,false,527521509)},[_c('span',[_vm._v("Clear filters")])])]:_vm._e()],2),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading,"disabled":_vm.loading,"text":""},on:{"click":_vm.getCalls}},[_c('v-icon',[_vm._v("mdi-sync")]),_vm._v(" "+_vm._s(_vm.$t("labelUpdate"))+" ")],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.heads,"items":_vm.missedcall,"footer-props":_vm.footerProps,"options":_vm.options,"server-items-length":_vm.totalmiss,"item-key":"index","loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.from",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"phone-number"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":_vm.statusColor(item.status)}},[_vm._v(" "+_vm._s(_vm.itemStatus(item))+" ")]),_vm._v(" "+_vm._s(_vm.getFrom(item))+" ")],1)]}},{key:"item.to",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"phone-number"},[(!_vm.admin)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":_vm.statusColor(item.status)}},[_vm._v(_vm._s(_vm.itemStatus(item)))]):_vm._e(),_vm._v(" "+_vm._s(_vm.getTo(item))+" ")],1)]}},{key:"item.duration",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.prettyTime(item.duration)))])]}},{key:"item.origin",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm._origin(item)))])]}},{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm._name(item)))])]}},{key:"item.createAt",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.formatDate(item.createAt)))])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [(_vm.isSuper)?[_c('v-btn',{attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.setRecordToRemove(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]:[_c('btn-make-call',{attrs:{"fromcall":true,"numberPatient":_vm.numberShow(item)}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"info"},on:{"click":function($event){return _vm.setContact(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-message-text-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Send SMS")])])]]}}],null,true)},[_vm._v(" "+_vm._s(/* From agent */)+" "),_vm._v(" "+_vm._s(/* To agent */)+" "),_vm._v(" "+_vm._s(/* Duration */)+" "),_vm._v(" "+_vm._s(/* Origin */)+" "),_vm._v(" "+_vm._s(/* Name */)+" "),_vm._v(" "+_vm._s(/* Date */)+" "),_vm._v(" "+_vm._s(/* Remove record */)+" "),_c('not-records',{attrs:{"slot":"no-data"},slot:"no-data"})],1),_vm._v(" "+_vm._s(/* Confirm remove */)+" "),_c('v-dialog',{attrs:{"max-width":"280","content-class":"rounded-xl","persistent":_vm.loading},model:{value:(_vm.dialogConfirmRemove),callback:function ($$v) {_vm.dialogConfirmRemove=$$v},expression:"dialogConfirmRemove"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6 font-weight-regular rounded-xl"},[_vm._v(" "+_vm._s(_vm.$t("dialogTitle"))+" ")]),_c('v-layout',{staticClass:"pa-4",attrs:{"column":""}},[_c('div',{staticClass:"text-body-1 text-truncate d-flex mb-2"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"green"}},[_vm._v("mdi-phone-outgoing")]),_c('span',{staticClass:"dosis"},[_vm._v(_vm._s(_vm.currentRecord.from))])],1),_c('div',{staticClass:"text-body-1 text-truncate d-flex"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"green"}},[_vm._v("mdi-phone-incoming")]),_c('span',{staticClass:"dosis"},[_vm._v(_vm._s(_vm.cleanPhone(_vm.currentRecord.to)))])],1)]),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"success","disabled":_vm.loading,"text":""},on:{"click":function($event){_vm.dialogConfirmRemove = false}}},[_vm._v(" "+_vm._s(_vm.$t("labelCancel"))+" ")]),_c('v-btn',{attrs:{"color":"red","loading":_vm.loading,"text":""},on:{"click":_vm.remove}},[_vm._v(" "+_vm._s(_vm.$t("labelRemove"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }